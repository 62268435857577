import { Container, Flex, spacing, grey } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { BreakpointWidth } from '@peloton/styles';
import { TrackingEvent } from '@ecomm/analytics/models';
import { Link } from '@ecomm/internationalize-ui';
import type { ProductDetailsCardProps } from '../types';
import ShopButton from './ShopButton';

type ProductCardContainerProps = {
  direction: 'horizontal' | 'vertical';
  productCard: ProductDetailsCardProps;
  isHovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  children: React.ReactNode;
  showShopButton?: boolean;
};

export const ProductCardContainer: React.FC<ProductCardContainerProps> = ({
  direction,
  productCard,
  isHovered,
  children,
  onMouseEnter,
  onMouseLeave,
  showShopButton = true,
}) => {
  const { trackEvent } = useTracking();
  const isSmallScreen = useMedia(`(max-width: ${BreakpointWidth.smallScreen}px)`, false);

  const trackProductCardClicked = () => {
    trackEvent({
      event: TrackingEvent.ClickedNavigation,
      properties: {
        category: productCard?.key,
        linkTo:
          productCard?.primaryCTA?.link?.url ?? productCard?.secondaryCTA?.link?.url,
        linkName: 'Product Cards - Hamburger',
      },
    });
  };

  return (
    <Container
      width={
        direction === 'vertical'
          ? {
              mobile: isSmallScreen ? '160px' : 'calc(50% - 8px)',
              tablet: 'calc(50% - 8px)',
              desktop: '240px',
            }
          : {
              mobile: isSmallScreen ? '336px' : 'calc(100% - 8px)',
              tablet: '100%',
              desktop: '508px',
            }
      }
      maxWidth={direction === 'vertical' ? '240px' : '508px'}
    >
      <Flex flexDirection="column" gap={{ mobile: spacing[16], desktop: spacing[24] }}>
        <StyledLink
          href={
            productCard?.primaryCTA?.link?.url ?? productCard?.secondaryCTA?.link?.url
          }
          onClick={trackProductCardClicked}
          hasUnderline={false}
          data-test-id="product-card-link"
        >
          <StyledProductCardOverlay
            padding={
              direction === 'horizontal'
                ? {
                    mobile: `${spacing[24]} 20px`,
                    tablet: `${spacing[24]} ${spacing[24]} ${spacing[24]} 20px`,
                  }
                : {
                    mobile: `${spacing[24]} ${spacing[16]}`,
                    tablet: `${spacing[24]} 20px`,
                  }
            }
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            direction={direction}
            isHovered={isHovered}
            borderRadius={spacing[16]}
          >
            <Flex
              flexDirection={direction === 'horizontal' ? 'row' : 'column'}
              alignItems={direction === 'horizontal' ? 'center' : undefined}
              gap={
                direction === 'horizontal'
                  ? { mobile: spacing[32], desktop: spacing[48] }
                  : undefined
              }
            >
              {children}
            </Flex>
          </StyledProductCardOverlay>
        </StyledLink>
        {showShopButton && (
          <ShopButton data-test-id="shop-button" productCard={productCard} />
        )}
      </Flex>
    </Container>
  );
};

const StyledProductCardOverlay = styled(Container)<{
  direction: 'horizontal' | 'vertical';
  isHovered: boolean;
}>`
  transition: all 300ms ease-in-out;
  background: ${({ direction, isHovered }) => {
    if (direction === 'horizontal') {
      if (isHovered) {
        return `radial-gradient(221.66% 110.18% at 18.7% 35.4%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.00) 100%), ${grey[75]}`;
      } else {
        return `radial-gradient(221.66% 110.18% at 18.7% 35.4%, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), ${grey[80]}`;
      }
    } else {
      if (isHovered) {
        return `radial-gradient(109.76% 126.09% at 28.8% 23.55%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.00) 100%), ${grey[75]}`;
      } else {
        return `radial-gradient(109.76% 126.09% at 28.8% 23.55%, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), ${grey[80]}`;
      }
    }
  }};
`;

const StyledLink = styled(Link)`
  margin: 0 !important;
`;
