import { grey, spacing, Container } from '@pelotoncycle/design-system';
import React, { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { toLocaleWithFallback } from '@peloton/internationalize/models/locale';
import { checkAndRemoveLocalePrefix } from '@peloton/internationalize/models/path';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { media, BreakpointWidth } from '@peloton/styles';
import useNav from '@content/client/www/nav/useNav';
import { gradientFromScrollPosition, scrollRatio } from '@ecomm/header/utils';
import useScrollPosition from '@ecomm/hooks/useScrollPosition';
import { HomeLogo } from './HomeLogo';
import { IconRow } from './IconRow';
import { MobileNav } from './MobileNav/MobileNav';
import { NavProvider } from './NavProvider';
import { ProductSubNavRow } from './ProductTopNav/ProductSubNavRow';
import {
  NAV_HEIGHT_MOBILE,
  NAV_HEIGHT_TABLET_XLARGE,
  NAV_HEIGHT_DESKTOP_LARGE,
} from './styles';
import type { DealsSubNavProps, VariationReferenceType } from './types';
// import { navContentfulMock } from './NavContentfulMock';

export type NavProps = {
  dealsSubNav: DealsSubNavProps;
  productSubNavs: VariationReferenceType<
    'navProductSubNav',
    ['navProductSubNav', 'navProductSubNav']
  >[];
  geoPickerMenu: VariationReferenceType<'navGeoPicker', ['navGeoPicker', 'navGeoPicker']>;
  accountMenu: VariationReferenceType<'navAccount', ['navAccount', 'navAccount']>;
  cart: VariationReferenceType<'navCart', ['navCart', 'navCart']>;
  hamburgerMenu: VariationReferenceType<
    'navHamburgerMenu',
    ['navHamburgerMenu', 'navHamburgerMenu']
  >;
};

const Nav: FC = () => {
  const locale = useLocale();
  const { content, isLoading } = useNav(toLocaleWithFallback(locale));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isDesktop = useMedia(`(min-width: ${BreakpointWidth.desktopLarge}px)`);
  const { position: currentPosition, setPosition } = useScrollPosition();
  const isHomePage = checkAndRemoveLocalePrefix(window.location.pathname) === '/';
  const transparentNavStyle = useMemo(() => {
    const backgroundStyles = isHomePage
      ? { background: gradientFromScrollPosition(currentPosition) }
      : {};
    return {
      ...backgroundStyles,
    };
  }, [currentPosition, isHomePage]);

  useEffect(() => {
    const initialLoadPosition = scrollRatio(window.pageYOffset);
    setPosition(initialLoadPosition);
  }, [setPosition]);

  if (isLoading) {
    return null; // We'll display nav loading state here
  }

  const {
    dealsSubNav,
    productSubNavs,
    geoPickerMenu,
    accountMenu,
    cart,
    hamburgerMenu,
  } = content;

  // Uncomment to use navContentfulMock for dev work
  // const {
  //   dealsSubNav,
  //   productSubNavs,
  //   geoPickerMenu,
  //   accountMenu,
  //   cart,
  //   hamburgerMenu,
  // } = navContentfulMock;

  return (
    <NavProvider>
      {isDesktop ? (
        <StyledNavContainer data-test-id="globalHeader" style={transparentNavStyle}>
          {isMenuOpen && <GradientOverlay />}
          <HomeLogo />
          <ProductSubNavRow
            dealsSubNav={dealsSubNav}
            productSubNavs={productSubNavs}
            setIsMenuOpen={setIsMenuOpen}
          />
          <IconRow
            accountMenu={accountMenu}
            geoPickerMenu={geoPickerMenu}
            cart={cart}
            hamburgerMenu={hamburgerMenu}
            productSubNavs={productSubNavs}
          />
        </StyledNavContainer>
      ) : (
        <MobileNav
          productSubNavs={productSubNavs}
          geoPickerMenu={geoPickerMenu}
          accountMenu={accountMenu}
          cart={cart}
          hamburgerMenu={hamburgerMenu}
        />
      )}
    </NavProvider>
  );
};

const GradientOverlay = styled.div`
  content: '';
  background: linear-gradient(to bottom, ${grey[90]}, rgba(25, 28, 32, 0) 69%);
  position: absolute;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  top: 0;
  left: 0;
  transition: opacity 200ms ease;
  opacity: 1;
  pointer-events: none;
  z-index: 0;
`;

const StyledNavContainer = styled(Container)`
  position: relative;
  height: ${NAV_HEIGHT_MOBILE};
  background-color: ${grey[90]};
  display: flex;
  align-items: center;
  padding: 0 ${spacing[16]};
  gap: 100px;

  ${media.tabletXLarge`
    height: ${NAV_HEIGHT_TABLET_XLARGE};
    padding: 0 ${spacing[24]};
  `}

  ${media.desktopLarge`
    height: ${NAV_HEIGHT_DESKTOP_LARGE};
  `}
`;

export default Nav;
