import { black, white, spacing, Container, Flex } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { NAV_HEIGHT_DESKTOP_LARGE, topNavAnimation } from '../styles';
import type { ProductSubNavProps } from '../types';
import { ProductDetailsCard } from './ProductDetailsCard';

const ProductNavMenu: FC<ProductSubNavProps> = ({ productDetailCards }) => {
  return (
    <StyledProductNavMenu>
      <Flex
        flexDirection="column"
        padding={`${spacing[48]} ${spacing[64]} 46px`}
        gap={spacing[40]}
      >
        {productDetailCards?.map(productDetailCard => (
          <ProductDetailsCard {...productDetailCard} key={productDetailCard.key} />
        ))}
      </Flex>
    </StyledProductNavMenu>
  );
};

const StyledProductNavMenu = styled(Container)`
  position: absolute;
  z-index: 1;
  top: ${NAV_HEIGHT_DESKTOP_LARGE};
  left: 50%;
  width: 485px;
  background-color: ${white};
  border-radius: ${spacing[4]};
  box-shadow: 0 30px ${spacing[80]} 0 ${rgba(black, 0.37)};
  ${topNavAnimation}
  transform: translateX(-50%);
`;

export default ProductNavMenu;
