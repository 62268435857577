import { toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import { toCurrencyfromLocale } from '@ecomm/graphql-bridge';
import type { ProductSelection } from '@ecomm/graphql/types.generated';
import { getCtVariantBySelection } from '@ecomm/shop/commercetools/getCtVariantBySelection';
import type { VariantsBySelectionsQuery } from '@ecomm/shop/graphql/VariantsBySelections.generated';

export type Variants = VariantsBySelectionsQuery['catalog']['variantsBySelections'];

const useDynamicVariantsBySelectionsQuery = () => {
  const baseLocale = toLocaleFromTLD();
  const currency = toCurrencyfromLocale(baseLocale);

  return async (productSelections: ProductSelection[]): Promise<Variants> => {
    return getCtVariantBySelection(baseLocale, currency, productSelections);
  };
};

export default useDynamicVariantsBySelectionsQuery;
